@tailwind base;
@tailwind components;
@tailwind utilities;

/** Custom styles go here **/
#balance-checkout > div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
